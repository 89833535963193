

























































































































































// Core
import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'
import Debounce from '@/decorators/Debounce'

// Store
import {userNamespace} from '@store/user'
import {NaturalPerson, UsersState} from '@store/users/interfaces'

// Types
import {UsersActions} from '@store/users/types'

// Interfaces
import {ISelect} from '@/interfaces/Interface'
import {Meta, Paginate, RequestParams} from '@store/interfaces'

const NSUsers = namespace('usersModule')

@Component({
  name: 'NaturalPersons',

  components: {
    'v-dialog-add-natural': () => import('@/components/dialogs/DialogAddIndividualWithLegal.vue'),
    'v-dialog-edit-natural': () => import('@/components/dialogs/DialogEditIndividual.vue'),
  },
})
export default class NaturalPersonsComponent extends Vue {
  @userNamespace.State('selectedProject')
  private selectedProjectId!: number

  @NSUsers.State((state: UsersState) => state.naturalPersons.data)
  private naturalPersons!: NaturalPerson[]

  @NSUsers.State((state: UsersState) => state.naturalPersons.meta)
  private meta!: Meta

  @NSUsers.Action(UsersActions.A_FETCH_NATURAL_PERSONS)
  private fetchNaturalPersons!: (params?: RequestParams) => Promise<Paginate<NaturalPerson>>

  @NSUsers.Action(UsersActions.A_TOGGLE_PERSON_ACTIVE)
  private togglePersonActive!: (person: NaturalPerson) => Promise<any>

  private visibleDialogAddNatural: boolean = false
  private visibleDialogEditNatural: boolean = false

  private params: RequestParams = {
    search: '',
    isActive: '',
  }

  private loading: boolean = false

  private statusOptions: ISelect[] = [
    {
      label: 'Активен',
      value: '1',
    },
    {
      label: 'Не активен',
      value: '0',
    },
  ]

  private person: NaturalPerson | null = null

  created() {
    this.params.projectId = this.selectedProjectId
    this.changePage()
  }

  @Debounce(1000)
  private handleChangeSearch() {
    const search = this.params.search as string

    if (search.length >= 3 || search.length === 0) {
      this.changePage()
    }
  }

  private changeStatus() {
    this.changePage()
  }

  private async changePage(page = 1) {
    this.loading = true
    await this.fetchNaturalPersons({page, ...this.params})
    this.loading = false
  }

  private handleTogglePersonActive(person: NaturalPerson) {
    this.loading = true
    this.togglePersonActive(person)
      .then(() => {
        this.$successNotify()
      })
      .catch(error => {
        this.$errorNotify(error?.response?.data?.message)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleEditPerson(person: NaturalPerson) {
    this.person = person
    this.visibleDialogEditNatural = true
  }
}
